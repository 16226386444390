import { Crop, CropEnum } from "common/types/graphql"
import { ImageAspectRatio } from "../types"

// When searching for the closest crop to a target aspect ratio, the aspect
// ratio delta needs to be within this limit, otherwise you will end up
// with cases where a container that expects a square crop could use an
// inappropriate ultrawide one, if there was no squareish crop set.
//
// See Asset::Image rails model
const CLOSENESS = 0.2

const isFreeCrop = (crop: Crop) => {
  return crop.type == CropEnum.Free
}

/**
 * Picks the closest crop to the desired aspect ratio.
 *
 * @param aspectRatio The desired aspect ratio.
 * @param crops The list of possible crops.
 */
export function closestCrop(aspectRatio: ImageAspectRatio, crops: Crop[]) {
  const parts = aspectRatio.split(":").map(Number)
  const ar = parts[0] / parts[1]

  const { crop, delta } = crops.reduce(
    (current: { crop: Crop | null; delta: number }, crop) => {
      const delta = Math.abs(crop.width / crop.height - ar)
      return delta < current.delta ? { crop, delta } : current
    },
    { crop: null, delta: Infinity },
  )

  if (delta < CLOSENESS) {
    return crop
  } else {
    // Prefer to use the free crop as the default if it's been set, otherwise
    // use the original image (uncropped).
    return crops.find(isFreeCrop) || null
  }
}
